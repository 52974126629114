<!-- 人脸识别认证 -->
<template>
  <div class="face-check-container">
    <div class="steps-container">
      <van-steps :active="active" active-color="#FE8A3D">
        <van-step>填写信息</van-step>
        <van-step>人脸验证</van-step>
        <van-step>认证成功</van-step>
      </van-steps>
    </div>
    <div class="main">
      <img src="../../assets/image/face-check-avatar.png" alt="" />
      <div class="desc">请使用前置摄像头录制，时长3-5秒钟，录制时请保证人脸处于镜头内并眨眨眼睛。</div>

      <div class="confirm-button">
        <input class="upload-input" type="file" accept="video/*" capture="user" id="fileVideo" @change="fileChange" />
        <span>授权并验证</span>
      </div>
      <p class="tips">
        点击按钮，即您同意<a href="https://static.51tsbx.com/h5/product/common/pdf/html/faceAgreement.html">《人脸识别授权同意书》</a>并确认授权
      </p>
    </div>
  </div>
</template>

<script>
import { ocrCheck, uploadQiniupRequest } from "../../api/api"
import { getSession, setSession } from "../../util/util"
const { VUE_APP_BASE_QINIU_URL } = process.env
export default {
  data() {
    return {
      active: 1,
      isLoading: "",
      faceAgreement: "",
    }
  },
  created() {
    this.faceAgreement = VUE_APP_BASE_QINIU_URL + "/h5/product/common/pdf/html/faceAgreement.html"
  },
  methods: {
    // 录制视频
    fileChange(e) {
      const _size = (e.target.files[0].size / 1024 / 1024).toFixed(4)

      if (_size > 20) {
        this.$toast("视频大小不能超过20MB，请重新录制！")
        return
      }

      this.isLoading = this.$toast.loading({
        overlay: true,
        forbidClick: true,
        message: "认证中，请稍后...",
        duration: 0,
      })
      this.faceCheck(e.target.files[0])
    },
    async faceCheck(file) {
      try {
        const res = await uploadQiniupRequest(file, "/data/policyMaster/")
        try {
          const ocrRes = await ocrCheck({
            type: 2,
            imageUrl: VUE_APP_BASE_QINIU_URL + res.url,
          })
          console.log("ocrRes: ", ocrRes)
          this.$toast("认证成功！")
        } catch (error) {
          let obj = document.getElementById("fileVideo")
          obj.value = ""
          console.log("error: ", error)
          return
        }
        //认证成功后需要把登录的信息更新  step改为2
        let info = getSession("policyToolsInfo")
        info.step = 2
        setSession("policyToolsInfo", info)
        this.$router.push("/")
      } catch (error) {
        this.isLoading.clear()
        console.log("error: ", error)
      }
    },
  },
}
</script>
<style lang="less" scoped>
.face-check-container {
  padding: 40px 24px 0;
  .steps-container {
    width: 702px;
    margin: 0 auto;
  }
  .main {
    text-align: center;
    margin-top: 105px;
    img {
      width: 538px;
      height: 482px;
      margin: 0 auto;
    }
    .desc {
      margin-top: 40px;
      font-size: 28px;
      color: #333333;
      line-height: 40px;
    }
    .confirm-button {
      position: relative;
      width: 619px;
      height: 92px;
      margin: 100px auto 0;
      font-size: 32px;
      color: #fff;
      line-height: 92px;
      background: linear-gradient(270deg, #ffbe70 0%, #fe8a3d 100%);
      box-shadow: 0px 6px 0px 0px rgba(253, 154, 77, 0.19);
      border-radius: 46px;
      .upload-input {
        opacity: 0;
        position: absolute;
        width: 619px;
        height: 92px;
        z-index: 2;
        left: 0;
      }
      span {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        z-index: 1;
      }
    }

    .tips {
      margin-top: 13px;
      font-size: 24px;
      color: #777;
      line-height: 33px;
    }
  }
}
</style>
